import React from "react";

const Support = ({ color }) => {
  return (
    <svg viewBox="0 0 37 31" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.24 0.597656H2.4C1.07448 0.597656 0 1.67233 0 2.99808V23.1617C0 24.4874 1.07456 25.5621 2.4 25.5621H12.968L16.4834 29.7423C16.9394 30.2846 17.6116 30.5977 18.3201 30.5977C19.0286 30.5977 19.7007 30.2846 20.1568 29.7423L23.6722 25.5621H34.24C35.5654 25.5621 36.64 24.4874 36.64 23.1617V2.99808C36.64 1.67233 35.5654 0.597656 34.24 0.597656ZM25.9133 19.8011H6.62832C5.9656 19.8011 5.42832 19.2637 5.42832 18.6009C5.42832 17.938 5.9656 17.4007 6.62832 17.4007H25.9133C26.576 17.4007 27.1133 17.938 27.1133 18.6009C27.1133 19.2637 26.576 19.8011 25.9133 19.8011ZM5.42832 13.1776C5.42832 12.5147 5.9656 11.9774 6.62832 11.9774H21.4366C22.0993 11.9774 22.6366 12.5147 22.6366 13.1776C22.6366 13.8404 22.0993 14.3778 21.4366 14.3778H6.62832C5.9656 14.3778 5.42832 13.8404 5.42832 13.1776ZM30.0117 8.95435H6.62832C5.9656 8.95435 5.42832 8.41697 5.42832 7.75414C5.42832 7.0913 5.9656 6.55392 6.62832 6.55392H30.0116C30.6743 6.55392 31.2116 7.0913 31.2116 7.75414C31.2117 8.41697 30.6744 8.95435 30.0117 8.95435Z"
        fill="#333333"
      />
    </svg>
  );
};
export default Support;
